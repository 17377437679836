<template>
  <v-container
    v-if="$can([titlePerms + '_list'])"
    id="crud"
    fluid
    tag="section"
  >
    <!--<v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
			</v-toolbar> </v-card
		>-->
    <v-row
      ><v-col cols="auto" md="12" class="">
        <Tabla
          :items="clientesAnmac"
          :headers="headers"
          :buscador="true"
          :title="title"
          :topBarBtn="$can(['alta_cliente']) == true ? 'Nuevo Cliente' : null"
          @topBarBtnAcc="$can(['alta_cliente']) == true ? agregarCliente : null"
          :showSelect="true"
          item_key="documento"
          @dataselect="selectCliente"
        />
        <ClienteAnmac
          v-if="dialog && $can(['alta_cliente'])"
          :item="editedItem"
          :action="accionProducto"
          @terminar="dialog = !dialog"
        />
      </v-col>
      <!-- <Carrito @editarCarrito="editProductos" /> -->
    </v-row>
    <v-row class="estampillas_route py-4 px-4">
      <router-link to="/"
        ><v-btn
          density="comfortable"
          color="primary"
          class="font-weight-bold btn__rounded text-none mx-0"
        >
          <v-icon color="white" class="px-1">mdi-arrow-left-thin</v-icon>
          Atrás
        </v-btn>
      </router-link>
      <router-link
        v-if="$can([titlePerms + '_sell'])"
        to="/estampillas_Anmac_Venta_productos"
        :style="{ pointerEvents: habilitaCompraLnk }"
        ><v-btn
          v-if="$can([titlePerms + '_sell'])"
          density="comfortable"
          color="primary"
          class="font-weight-bold btn__rounded text-none mx-0"
          :disabled="habilitaCompraBtn"
        >
          Continuar
          <v-icon color="white" class="px-1">mdi-arrow-right-thin</v-icon>
        </v-btn>
      </router-link>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Carrito from "@/views/carrito/Carrito.vue";
import ClienteAnmac from "@/views/dashboard/components/secundarios/ClienteAnmac.vue";
import Tabla from "@/components/design-system/base/TablaVenta.vue";


function title() {
  return "Clientes ANMAC";
}

export default {
  components: {
    Carrito,
    ClienteAnmac,
    Tabla,
  },

  data: (vm) => ({
    titlePerms: "estampillas_anmac",
    accionProducto: "",
    snackbar: false,
    text: "Registro Insertado",

    color: "success",
    timeout: 4000,
    title: title(),
    search: "",
    valid: true,
    editedItem: {
      nombre: "",
      id: "",
    },
    cliente: [],
    defaultItem: {
      nombre: "",
      id: "",
    },
    keyTableAutorizados: 0,
    dialog: false,
    readonly: true,
    readOnlySubtotal: true,
    headers: [
      { text: "Nombre", filterable: true, value: "name" },
      { text: "Documento", filterable: true, value: "documento" },
      { text: "Ciudad", filterable: true, value: "localidad" },
      { text: "Celular", filterable: true, value: "phone" },
      { text: "Mail", filterable: true, value: "email" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    habilitaCompraLnk: "none",
    habilitaCompraBtn: true,
  }),

  created() {},

  mounted() {
    console.log("Componente " + this.baseUrl + " creado");
    this.$store.commit("SET_CART_EDIT_PATH", "/estampillas_ANMAC");
    this.fetchAnmac();
    this.resetSourceVentaElementosAduana();
    this.clienteAnmac == undefined;
  },

  computed: {
    ...mapState("anmac", ["clientesAnmac", "clienteAnmac"]),
  },

  methods: {
    ...mapActions("anmac", ["fetchAnmac"]),
    ...mapMutations("anmac", ["setClienteAnmac"]),
    ...mapMutations("aduana", ["resetSourceVentaElementosAduana"]),
    ...mapMutations("carrito", ["editProducto"]),

    selectCliente(cliente) {
      this.setClienteAnmac(cliente);
      this.clienteAnmac === undefined
        ? (this.habilitaCompraLnk = "none")
        : (this.habilitaCompraLnk = "");
      this.clienteAnmac === undefined
        ? (this.habilitaCompraBtn = true)
        : (this.habilitaCompraBtn = false);
    },
    agregarCliente() {
      this.accionProducto = "REGISTRAR CLIENTE";
      this.dialog = true;
    },
    editarCliente(item) {
      this.editedItem = Object.assign({}, item);
      this.accionProducto = "EDITAR CLIENTE";
      this.dialog = true;
    },
  },

  watch: {},
};
</script>

<style>
.data__table {
  overflow-y: auto;
  max-height: 50.75rem;
}
.estampillas_route {
  display: flex;
  justify-content: space-between;
}
</style>
