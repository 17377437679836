<template>
  <v-container id="EstampillasAnmacVenta" fluid tag="section">
    <v-row
      ><v-col cols="auto" md="8" class="">
        <!-- <v-data-table
          :headers="headers"
          :items="estampillasAnmac"
          :search="search"
          sort-by="secret"
          class="elevation-4 data__table__EstampillasAnmacVenta"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              :color="$cv('btnEditar')"
              title="Agregar"
              @click="AgregarProductos(item)"
            >
              mdi-plus-box
            </v-icon>
          </template>

          <template v-slot:top>
            <v-row class="pt-3 px-8">
              <v-col sm="12" >
                <div class="flex__cliente__EstampillasAnmacVenta">
                  <div>
                    <span class="display-1"> <b class="h-6"> Cliente: </b>{{ clienteAnmac.name }} </span>
                  </div>
                  <div>
                    <span class="display-1"> <b> Documento: </b>{{ clienteAnmac.documento }} </span>
                  </div>
                  <div>
                    <router-link to="/estampillas_ANMAC">
                      <v-btn
                        small
                        density="compact"
                        color="primary"
                        class="btn__rounded"
                        >cambiar &nbsp
                        <v-icon color="white">mdi-account-convert</v-icon>
                      </v-btn>
                    </router-link>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-toolbar flat color="white" class="pr-2">
              <v-row>
                <v-col sm="12" class="">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="'Busqueda de ' + title"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
        </v-data-table> -->
        <Tabla
          :items="estampillasAnmac"
          :headers="headers"
          :buscador="true"
          :title="title"
          :action="true"
          @actionRtn="agregarProductos"
          :topRow="true"
          :topRowArray="[{text:'Cliente', value:clienteAnmac.name+' '+clienteAnmac.lastName},
                         {text:'Documento', value:clienteAnmac.documento},
                         {btnText: 'Cambiar Cliente', btnPath: '/estampillas_ANMAC_venta', btnIcon: 'mdi-cached'}]"
        />
        <AgregarProductos
          v-if="dialog"
          :item="editedItem"
          :action="accionProducto"
          @terminar="dialog = !dialog"
        />
      </v-col>
      <Carrito @editarCarrito="editProductos" />
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Carrito from "@/views/carrito/Carrito.vue";
import AgregarProductos from "@/views/dashboard/components/secundarios/AgregarProductos.vue";
import Tabla from "@/components/design-system/base/TablaVenta.vue";
import EstampillasAnmac from "../EstampillasAnmac.vue";

function title() {
  return "Elementos Registrales";
}

export default {
  components: {
    Carrito,
    AgregarProductos,
    Tabla,
  },

  data: (vm) => ({
    accionProducto: "",
    snackbar: false,
    text: "Registro Insertado",

    color: "success",
    timeout: 4000,
    title: title(),
    search: "",
    valid: true,
    editedItem: {
      nombre: "",
      id: "",
    },
    defaultItem: {
      nombre: "",
      id: "",
    },
    keyTableAutorizados: 0,
    dialog: false,
    readonly: true,
    readOnlySubtotal: true,
    headers: [
      { text: "Código", filterable: true, value: "codigo" },
      { text: "Producto", filterable: true, value: "nombre" },
      { text: "P. Unitario", filterable: true, value: "precio" },
      { text: "Stock", filterable: true, value: "stock" },
      { text: "Acciones", value: "actions", sortable: false },
    ],

  }),

  created() {},

  mounted() {
    console.log("Componente " + this.baseUrl + " creado");
    this.$store.commit("SET_CART_EDIT_PATH", "/elementos_registrales");
    this.fetchAnmacEstampillas();
  },

  computed: {
    ...mapState("carrito", ["carrito", "total"]),
    ...mapState("anmac", ["estampillasAnmac", "clienteAnmac"]),
  },

  methods: {
    ...mapMutations("carrito", ["editProducto"]),
    ...mapActions("anmac", ["fetchAnmac", "fetchAnmacEstampillas"]),

    agregarProductos(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.subtotal = 0;
      this.accionProducto = "AGREGAR ESTAMPILLAS";
      this.dialog = true;
    },
    editProductos(item) {
      this.editedItem = Object.assign({}, item);
      this.accionProducto = "EDITAR ESTAMPILLAS";
      this.dialog = true;
    },
  },
};
</script>

<style>
.cambiar__btn__EstampillasAnmacVenta {
  max-height: 2rem;
  max-width: 7.5rem;
}
</style>
